* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
html {
  font-size: 62.5%;
}
html,
body {
  height: 100%;
}
body {
  font-size: 1.5rem;
  -webkit-font-smoothing: subpixel-antialiased;
}
ul {
  list-style-type: none;
}
table {
  border: none !important;
  border-collapse: collapse;
}
table th,
table tr,
table td {
  border: none;
  padding: 0;
}
table tr {
  border-bottom: 1px solid $silver;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  line-height: 1.2em;
  color: $baseColour;
}
h1 {
  font-size: 4.7rem;
}
h2 {
  font-size: 2.8rem;
}
img {
  display: block;
}
.hidden {
  display: none;
}
@-moz-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-webkit-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-o-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-moz-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-webkit-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-o-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-moz-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@-o-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
#body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1.5rem;
}
#body h1 {
  font-size: 2rem;
  font-weight: 400;
}
#body a {
  display: inline-flex;
  text-decoration: none;
  color: inherit;
}
#body .loader {
  position: absolute;
}
#body [data-type='http://zhdk.ch/hands_on/actors/'] {
  background-color: #2ecc71;
}
#body [data-type='http://zhdk.ch/hands_on/artifacts/'] {
  background-color: #3498db;
}
#body [data-type='http://zhdk.ch/hands_on/places/'] {
  background-color: #e67e22;
}
#body [data-type='http://zhdk.ch/hands_on/machines/'] {
  background-color: #9b59b6;
}
#body [data-type='http://zhdk.ch/hands_on/materials/'] {
  background-color: #95a5a6;
}
#body [data-type='http://zhdk.ch/hands_on/projects/'] {
  background-color: #e74c3c;
}
#body [data-type='http://zhdk.ch/hands_on/tools/'] {
  background-color: #f1c40f;
}
#body #root {
  height: 100%;
}
#body #root #App {
  height: 100%;
}
